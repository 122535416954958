import React from 'react';

function Speakers() {
    return ( 
       
    

       <div className="page-wrapper">
   {/* Preloader */}
  
  
   {/* Hidden bar back drop */}
   <div className="form-back-drop" />
 
   {/*Page Title*/}
   <section className="page-title" style={{backgroundImage: 'url(images/background/spek.png)'}}>
     <div className="anim-icons full-width">
       <span className="icon icon-bull-eye" />
       <span className="icon icon-dotted-circle" />
     </div>
     <div className="auto-container">
       <div className="title-outer">
         <h1>Speakers</h1>
         <ul className="page-breadcrumb">
           <li><a href="/">Home</a></li>
           <li>Speakers</li>
         </ul> 
       </div>
     </div>
   </section>
   {/*End Page Title*/}
   {/* Speakers Section */}
   <section className="speakers-section">
     <div className="anim-icons full-width">
       <span className="icon icon-dotted-circle" />
     </div>
     <div className="auto-container">
       <div className="sec-title text-center">
         {/* <span className="sub-title">Our SPEAKERS</span> */}
         <h2>Our SPEAKERS </h2>
         <span className="divider" />
       </div>
       <div className="row">
          {/* Speaker block */}
          <div className="speaker-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
          <div className="inner-box" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
             <div className="image-box">
               <figure className="image"><a href="speakerdetails_1"><img src="images/clients/sp2.PNG" alt /></a></figure>
               <span className="plus-icon fa fa-plus" />
               <div className="social-links">
                 <a href="#"><i className="fab fa-linkedin" /></a>
                
               </div>
             </div>
             <div className="info-box">
               <h4 className="name"><a href="speaker-detail.html"> Sam Clever</a></h4>
               <span className="designation">  Founder and CEO of NOSTRA Group</span> 
             </div>
           </div>
         </div>
     
       
         <div className="speaker-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
  <div 
    className="inner-box" 
    style={{ 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      textAlign: "center",
      width: "100%" // Assure une largeur uniforme
    }}
  >
    <div className="image-box" style={{ width: "100%" }}> {/* Permet d'adapter la largeur */}
      <figure className="image">
        <a href="speakerdetails3">
          <img src="images/clients/sp3.png" alt="" style={{ maxWidth: "100%", height: "auto" }} />
        </a>
      </figure>
      <span className="plus-icon fa fa-plus" />
      <div className="social-links">
        <a href="#"><i className="fab fa-linkedin" /></a>
      </div>
    </div>
    <div 
      className="info-box" 
      style={{ width: "100%", paddingTop: "10px" }} // Ajustement pour uniformiser
    >
      <h4 className="name">
        <a href="speakerdetails3">Rrahul Sethu</a>
      </h4>
     <span className="designation">Founder of Metaverse911 </span> 
    </div>
  </div>
</div>

<div className="speaker-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
  <div className="inner-box" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <div className="image-box">
      <figure className="image">
        <a href="/speakerdetails5">
          <img src="images/clients/sp5.jpg" alt="" />
        </a>
      </figure>
      <span className="plus-icon fa fa-plus" />
      <div className="social-links">
        <a href="#"><i className="fab fa-linkedin" /></a>
      </div>
    </div>
    <div className="info-box">
      <h4 className="name">
        <a href="speaker-detail.html">Philipp A. Rauschnabel</a>
      </h4>
      <span className="designation"> Professor at University of the Bundeswehr Munich</span> 
    </div>
  </div>
</div>
 
         <div className="speaker-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
  <div className="inner-box" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
    <div className="image-box">
      <figure className="image">
        <a href="/speakerdetails2">
          <img src="images/clients/sp1.jpg" alt="" />
        </a>
      </figure>
      <span className="plus-icon fa fa-plus" />
      <div className="social-links">
        <a href="#"><i className="fab fa-linkedin" /></a>
      </div>
    </div>
    <div className="info-box">
      <h4 className="name">
        <a href="speaker-detail.html">Ahmed Saffeh</a>
      </h4>
      <span className="designation"> Professor at Zayed University</span> 
    </div>
  </div>
</div>

         {/* Speaker block 
         <div className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms">
           <div className="inner-box">
             <div className="image-box">
               <figure className="image"><a href="speaker-detail.html"><img src="images/resource/speakers.jpg" alt /></a></figure>
               <span className="plus-icon fa fa-plus" />
               <div className="social-links">
                 <a href="#"><i className="fab fa-linkedin" /></a>
                 <a href="#"><i className="fab fa-facebook-f" /></a>
                 <a href="#"><i className="fab fa-twitter" /></a>
                 <a href="#"><i className="fab fa-pinterest-p" /></a>
               </div>
             </div>
             <div className="info-box">
               <h4 className="name"><a href="speaker-detail.html">sara</a></h4>
               <span className="designation">  CEO</span>
             </div>
           </div>
         </div>
        */}
       </div>
     </div>
   </section>
   {/* End Speakers Section */}
 
 
 </div>



    
    );
}

export default Speakers;
