import React from 'react'

function SpeakerDetails2() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/spek.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Speakers</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">conference</a></li>
                            <li>Speakers Details</li>
                        </ul>
                    </div>
                </div> 
            </section>

            <section className="speaker-detail-section">
    <div className="auto-container">
        <div className="row no-gutters">
        <div className="image-column col-lg-6 col-md-6 col-sm-12" style={{ paddingRight: '10px', textAlign: 'center' }}>
        <div className="speaker-block wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><a href="#"><img src="images/clients/sp1.jpg" alt /></a></figure>
                                            <span className="plus-icon fa fa-plus" />
                                            <div className="social-links">
                                                <a href="#"><i className="fab fa-linkedin" /></a>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="speaker-details" style={{ marginTop: '10px' }}>
                                        <h4 className="name"><a href="#">Ahmed Saffeh</a></h4>
                                        <span className="designation">Professor at University Professor</span>
                                        <ul className="speaker-info-list">
                                            <li><strong>Speciality:</strong> AI</li>
                                            <li><strong>Company:</strong> Zayed University</li>
                                            {/* <li><strong>Experience:</strong> 15 Years</li> */}
                                            {/* <li><strong>Email:</strong> <a href="Ahmed.Seffah@zu.ac.ae; ">Ahmed.Seffah@zu.ac.ae </a></li> */}
                                            
                                        </ul>
                                    </div>
                         
                           
                        </div>
                        <div className="info-column col-lg-6 col-md-6 col-sm-12" style={{ paddingLeft: '10px' }}>
                            <h3>Personal Information</h3>
                            <p style={{ textAlign: 'justify' }}>Ahmed Seffah is a University Professor and a Senior Consultant specializing in human-centric software engineering, AI-Powered Software Systems Engineering, and Human-AI Interaction, and their applications for addressing sustainability development goals. His professional journey spans both Canada and Europe for 40 years. He has chaired and designed numerous academic programs in software engineering since graduating as a critical safety system engineer in the late 80s. Concurrently, he has led the design and development of significant software systems across various sectors, including aeronautics, automotive, healthcare, and software development companies. </p>
                                
                            <p style={{ textAlign: 'justify' }}> These days, his work emphasizes the creation of green software and the role of AI-powered software systems as an enabler of green technology and sustainability, including E-waste, energy efficiency, food security, and farming. Prof. Seffah is a founding member of Green Byte Village (greenbytevialle.com) and the consortium for connected research for digital agriculture innovation (cocorai.org). Prof Seffah co-authored 8 books and more than 200 publications. He supervised 15 PhDs and more than 50 masters; many are academic professors and some are in leading positions in industry</p>
                            
                            </div>

</div>
</div>
            </section>

















        </>
    )
}

export default SpeakerDetails2