import React, { useState } from 'react';

function Track() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [openModal, setOpenModal] = useState(false);
    const [selectedCoordinator, setSelectedCoordinator] = useState(null);

    const coordinators = [
        {
            id: 1,
            image: "images/clients/HAFIDA.gif",
            name: "Hafida BOULEKBACHE ",
            // title: "Track Coordinator",
            // position: "Professor of Educational Technology"
        },
        {
            id: 2,
            image: "images/clients/ALEXIS.jpg",
            name: "Alexis Heloir ",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 3,
            image: "images/clients/rima.jpg",
            name: "Rima Grati",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 4,
            image: "images/clients/Khouloud.jpg",
            name: "Khouloud Boukadi",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 5,
            image: "images/clients/benjamin.jpg",
            name: "Benjamin Dewitte",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 6,
            image: "images/clients/vincent.jpg",
            name: "Vincents Reynaert",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 7,
            image: "images/clients/arnaud.jpg",
            name: "Arnaud Hufitier ",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        },
        {
            id: 8,
            image: "images/clients/c5.jpg",
            name: "Makram Mestiri ",
            // title: "Track Co-Coordinator",
            // position: "Associate Professor of Digital Learning"
        }
    ];

    const handleCoordinatorClick = (coordinator) => {
        setSelectedCoordinator(coordinator);
        setOpenModal(true);
    };

    const getCoordinatorsForTrack = (trackId) => {
        const trackMap = {
            1: [1, 2],
            2: [3, 4],
            3: [5, 6],
            4: [7, 8]
        };
        return coordinators.filter((coordinator) => trackMap[trackId].includes(coordinator.id));
    };

    return (
        <>
            <div className="col-lg-8">
                <ul className="accordion-box">
                    {/* Track 1 */}
                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 0 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(0)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 1: Future Education in the Metaverse
                            <span className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>

                        {activeIndex === 0 && (
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Pedagogies and educational approaches in the metaverse <br />
                                        • Technology enhanced science, technology, engineering & Math education (TeSTEM) <br />
                                        • Collaborative learning in the metaverse <br />
                                        • Assessment in the metaverse <br />
                                        • Recommender system in educational metaverse environments <br />
                                        • Virtual reality, augmented reality, mixed reality, and extended reality applications in education <br />
                                        • Automatic scalarization in educational <br />
                                        • Integration of digital twins into curriculum development <br />
                                        • Industry-academia collaborations in digital twin training initiatives <br />
                                        • 3D scanning and rendering techniques for creating digital twins <br />
                                        • Simulation and modeling techniques for educational purposes <br />
                                        • Medical simulation and training in metaverse environments <br />
                                        • Virtual patient interactions for healthcare education  <br />
                                        • Immersive audio and spatial sound in the metaverse.

                                        <div className="d-flex justify-content-end align-items-center me-4">
                                            {getCoordinatorsForTrack(1).map((coordinator) => (
                                                <div
                                                    key={coordinator.id}
                                                    className="rounded-circle border border-secondary overflow-hidden ms-2"
                                                    style={{ width: 50, height: 50, cursor: 'pointer' }}
                                                    onClick={() => handleCoordinatorClick(coordinator)}
                                                >
                                                    <img
                                                        src={coordinator.image}
                                                        alt={coordinator.name}
                                                        className="img-fluid h-100 object-fit-cover"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 2 */}
                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 1 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(1)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 2: Security, Sustainability and Accessibility in the Metaverse
                            <span className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>

                        {activeIndex === 1 && (
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                       
                                       
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Cybersecurity in metaverse <br />
                                        • Blockchain and smart contracts in metaverse <br />
                                        • NFT security <br />
                                        • Assistive technology for students with disabilities <br />
                                        • Equity, diversity and inclusion in metaverse <br />
                                        • Ethical metaverse <br />
                                        • Accessibility and usability of metaverse technologies <br />
                                        • Cognitive, motivational and affective aspects for personalization <br />
                                        • Measurement and evaluation in adaptive metaverse environments <br />
                                        • Design of adaptive avatars <br />
                                        • Metaverse and society 5.0 <br />
                                        • Sustainability in and of metaverse
                                        <div className="d-flex justify-content-end align-items-center me-4">
                                        {getCoordinatorsForTrack(2).map((coordinator) => (
                                                <div
                                                    key={coordinator.id}
                                                    className="rounded-circle border border-secondary overflow-hidden ms-2"
                                                    style={{ width: 50, height: 50, cursor: 'pointer' }}
                                                    onClick={() => handleCoordinatorClick(coordinator)}
                                                >
                                                    <img
                                                        src={coordinator.image}
                                                        alt={coordinator.name}
                                                        className="img-fluid h-100 object-fit-cover"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 3 */}
                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 2 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(2)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 3: Human Machine Interactions and Collaborations in Metaverse
                            <span className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>

                        {activeIndex === 2 && (
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Collaborative intelligence (human intelligence and artificial intelligence) in education <br />
                                        • Teaming up with the machine in education <br />
                                        • Interactions with VR, AR, MR, and XR <br />
                                        • Brain computer interaction <br />
                                        • Somatosensory interaction <br />
                                        • Big data and Learning analytics <br />
                                        • 3D AI Modeling
                                        <div className="d-flex justify-content-end align-items-center me-4">
                                            {getCoordinatorsForTrack(3).map((coordinator) => (
                                                <div
                                                    key={coordinator.id}
                                                    className="rounded-circle border border-secondary overflow-hidden ms-2"
                                                    style={{ width: 50, height: 50, cursor: 'pointer' }}
                                                    onClick={() => handleCoordinatorClick(coordinator)}
                                                >
                                                    <img
                                                        src={coordinator.image}
                                                        alt={coordinator.name}
                                                        className="img-fluid h-100 object-fit-cover"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 4 */}
                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 3 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(3)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 4: Innovations in Digital Heritage through AI and Blockchain in the Metaverse
                            <span className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>

                        {activeIndex === 3 && (
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        
                                        This track focuses on, but not limited to:
                                        <br />
                                        • AI and Machine Learning for Cultural Heritage <br />
                                        • Blockchain, NFTs, and Digital Provenance in Heritage Preservation <br />
                                        • Immersive Digital Heritage: Virtual and Augmented Reality <br />
                                        • Motion Capture for Heritage Gesture Reconstruction <br />
                                        • NeuroMetaverse: Advancements in Neuroscience, AI, and Digital Heritage <br />
                                        • Advanced Visualization Techniques for Heritage in the Metaverse <br />
                                        • Intangible heritage protection with NFT <br />
                                        • Digitizing Heritage: Scanning Technologies, AI, and the Metaverse
                                        <div className="d-flex justify-content-end align-items-center me-4">
                                            {getCoordinatorsForTrack(4).map((coordinator) => (
                                                <div
                                                    key={coordinator.id}
                                                    className="rounded-circle border border-secondary overflow-hidden ms-2"
                                                    style={{ width: 50, height: 50, cursor: 'pointer' }}
                                                    onClick={() => handleCoordinatorClick(coordinator)}
                                                >
                                                    <img
                                                        src={coordinator.image}
                                                        alt={coordinator.name}
                                                        className="img-fluid h-100 object-fit-cover"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
            </div>

            {/* End FAQ's section */}
 {/* Custom Modal */}
 {openModal && selectedCoordinator && (
                <div 
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000
                    }}
                    onClick={() => setOpenModal(false)}
                >
                    <div 
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '8px',
                            maxWidth: '400px',
                            width: '90%'
                        }}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="text-center">
                            <div 
                                className="rounded-circle overflow-hidden mx-auto mb-3"
                                style={{width: '80px', height: '80px'}}
                            >
                                <img 
                                    src={selectedCoordinator.image} 
                                    alt={selectedCoordinator.name}
                                    className="w-100 h-100 object-fit-cover"
                                />
                            </div>
                            <h3 className="h4 mb-2">{selectedCoordinator.name}</h3>
                            <p className="mb-1">{selectedCoordinator.title}</p>
                            <p className="text-muted small">{selectedCoordinator.position}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Track