import React from 'react'

function SpeakerDetails5() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/spek.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Speakers</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">conference</a></li>
                            <li>Speakers Details</li>
                        </ul>
                    </div>
                </div> 
            </section>

            <section className="speaker-detail-section">
    <div className="auto-container">
        <div className="row no-gutters">
        <div className="image-column col-lg-6 col-md-6 col-sm-12" style={{ paddingRight: '10px', textAlign: 'center' }}>
        <div className="speaker-block wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><a href="#"><img src="images/clients/sp5.jpg" alt /></a></figure>
                                            <span className="plus-icon fa fa-plus" />
                                            <div className="social-links">
                                                <a href="#"><i className="fab fa-linkedin" /></a>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="speaker-details" style={{ marginTop: '10px' }}>
                                        <h4 className="name"><a href="#">Philipp Rauschnabel</a></h4>
                                        <span className="designation">Professor at University of the Bundeswehr Munich</span>
                                        <ul className="speaker-info-list">
                                            <li><strong>Speciality:</strong>  Augmented Reality, Virtual Reality and Metaverse</li>
                                            <li><strong>Company:</strong> University of the Bundeswehr Munich</li>
                                            {/* <li><strong>Experience:</strong> 15 Years</li> */}
                                            {/* <li><strong>Email:</strong> <a href="Ahmed.Seffah@zu.ac.ae; ">Ahmed.Seffah@zu.ac.ae </a></li> */}
                                            
                                        </ul>
                                    </div>
                         
                           
                        </div>
                        <div className="info-column col-lg-6 col-md-6 col-sm-12" style={{ paddingLeft: '10px' }}>
                            <h3>Personal Information</h3>
                            <p style={{ textAlign: 'justify' }}> <strong>Philipp Rauschnabel</strong> is a professor of digital marketing and media innovation at the University of the Bundeswehr Munich. With his team, he develops scientifically sound, practical, and socially relevant answers related to new reality formats: Augmented Reality, Virtual Reality, and the Metaverse. </p>
                                
                            <p style={{ textAlign: 'justify' }}> His research is both theoretical and application-oriented. He cooperates in research and teaching with companies and associations, presents results at conferences, publishes them in peer-reviewed journals, and discusses developments in the media. In doing so, he relies on established theories and the latest standards of quantitative and qualitative research. </p>
                            
                            </div>

</div>
</div>
            </section>

















        </>
    )
}

export default SpeakerDetails5