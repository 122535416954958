import React from 'react'

function Register() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/11.jpg)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Registration free</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">Conference</a></li>
                            <li>Registration free</li>
                        </ul>
                    </div>
                </div>
            </section>




            <section className="pricing-section-two " style={{ backgroundImage: 'url(images/background/9.jpg)' }}>
                 
            

{/* */}
<div className="container">
  <div className="pricing-header">
    <h1>Conference Registration Fees</h1>
    <p>Select the registration type that matches your academic or professional profile</p>
  </div>
  <div className="pricing-table-container">
    <div className="fees-title">Participant Registration Fees</div>
    <table className="pricing-table">
      <thead>
        <tr>
          <th>Participant Type</th>
          <th>Basic Registration</th>
          <th>Full Registration</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="participant-type">Student (Bachelor/Master)</td>
          <td className="price">€50</td>
          <td className="price">€130</td>
        </tr>
        <tr>
          <td className="participant-type">PhD Student</td>
          <td className="price">€250</td>
          <td className="price">€330</td>
        </tr>
        <tr>
          <td className="participant-type">Non-Student</td>
          <td className="price">€300</td>
          <td className="price">€380</td>
        </tr>
        <tr>
          <td className="participant-type">Industry</td>
          <td className="price">€350</td>
          <td className="price">€400</td>
        </tr>
      </tbody>
    </table>
    <div className="vat-notice">
      <span className="red-star">★</span> All prices are exclusive of VAT.
    </div>
    <button className="register-button">Register Now</button>
  </div>
  <div className="includes-section">
    <div className="includes-card basic">
      <h3><span className="includes-icon basic-icon">B</span> Basic Registration Includes:</h3>
      <ul className="includes-list basic">
        <li>Access to 3-day conference and workshops</li>
        <li>1 ticket for the banquet</li>
        <li>Presentation and publication of one paper (the registrant must be a co-author of the associated paper)</li>
      </ul>
    </div>
    <div className="includes-card full">
      <h3><span className="includes-icon full-icon">F</span> Full Registration Includes:</h3>
      <ul className="includes-list full">
        <li>Access to 3-day conference and workshops</li>
        <li>1 ticket for the banquet</li>
        <li>Presentation and publication of one paper (the registrant must be a co-author of the associated paper)</li>
        <li>2 daily coffee breaks with lunch</li>
      </ul>
    </div>
  </div>
</div>


 

                 
           
            </section>







        </>
    )
}

export default Register