import React from 'react'

function SpeakerDetails() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/sp2.PNG)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Speakers</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">conference</a></li>
                            <li>Speakers Details</li>
                        </ul>
                    </div>
                </div> 
            </section>

            <section className="speaker-detail-section">
            <div className="auto-container">
        <div className="row no-gutters">
        <div className="image-column col-lg-6 col-md-6 col-sm-12" style={{ paddingRight: '10px', textAlign: 'center' }}>
        <div className="speaker-block wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><a href="#"><img src="images/clients/sp2.PNG" alt /></a></figure>
                                            <span className="plus-icon fa fa-plus" />
                                            <div className="social-links">
                                                <a href="#"><i className="fab fa-linkedin" /></a>
                                              
                                            </div>
                                      
                                    </div>
                                </div>
                                <div className="speaker-details" style={{ marginTop: '10px' }}>
                                    <div className="speaker-details">
                                        <h4 className="name"><a href="#"> Sam Clever</a></h4>
                                        <span className="designation"> Founder and CEO of NOSTRA Group </span>
                                        <ul className="speaker-info-list">
                                            <li><strong>Speciality:</strong> Metaverse </li>
                                            <li><strong>Company:</strong>NOSTRA Group</li>
                                          
                                            {/* <li><strong>Email:</strong> <a href="Ahmed.Seffah@zu.ac.ae; ">sc@nostraverse.com </a></li> */}
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-column col-lg-6 col-md-6 col-sm-12" style={{ paddingLeft: '10px' }}>
                            <h3>Personal Information</h3>
                            <p style={{ textAlign: 'justify' }}>Sam Clever is a visionary entrepreneur and thought leader in the Metaverse industry. As CEO and Founder of NOSTRA Group, he has been on a single mission: Transforming the way individuals and organizations engage with information by converting static content into interactive and gamified experiences. His work stands at the confluence of technology, business innovation, and community building, and he is spearheading digital transformation across sectors.</p>
                            <p style={{ textAlign: 'justify' }}>Sam's career has traversed various industries, such as real estate, insurance, Investment banking, software developement and fund management. He has achieved several successful business exits over the years. SAM CLEVER SPEAKER These opportunities shaped his ability in identifying market opportunities, crafting innovative strategies and creating successful businesses. Sam established NOSTRA Group in 2021, a company dedicated to the development of Metaverse platforms for Governments, Enterprises and other organizations. This innovative company excels at transforming conventional 2D data into interactive 3D environments, which can be accessed through virtual reality hardware as well as on the web. NOSTRA Group has become well-known for its pioneering projects and fresh approach to the Metaverse under the guidance of Sam, positioning itself as a front-runner for digital transformation worldwide.</p>
                        </div>
                    </div>
                     
                </div>
            </section>

















        </>
    )
}

export default SpeakerDetails