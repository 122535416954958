import React from 'react'

function Coomitee() {
    return (
        <>
         <section className="page-title2" style={{ backgroundImage: 'url(images/background/4.jpg)' }}>

<div className="auto-container">
    <div className="title-outer">
    <h1 style={{ color: '#181474' }}>Conference committees</h1>

         
    </div>
</div>
</section>
<section className="speakers-section-two"   style={{ backgroundImage: 'url(images/background/7.jpg)' , marginBottom: '100px' }}>  
<div className="anim-icons">
                    <span className="icon icon-object-10" />
                    <span className="icon icon-object-15" />
                    <span className="icon icon-object-16" /> 
                </div>
                <div className="auto-container" style={{ marginTop: "50px", marginBottom: "50px" }}>
                                     <div className="row d-flex justify-content-center ">
                        <div className="sec-title light text-center">

                            <h2 className='mt-3 mb-5'> Conference Founder</h2>
                            <span className="divider" />
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"> <img src="images/clients/c6.jpeg" alt /></figure>
                                  
                                </div>
                                <div className="info-box">
                                    <h4 className="name">Mourad Abed </h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                    </div>
<hr /> 

                    <div className="row justify-content-center ">
                        <div className="sec-title light text-center ">

                            <h2 className='mt-3 mb-5'> General Chairs</h2>
                            <span className="divider" />
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="300ms" style={{ visibility: 'visible', animationDelay: '300ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/c7.jpg" alt /></figure>
                                     
                                </div>
                                <div className="info-box">
                                    <h4 className="name">Abdelhakim ARTIBA</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="600ms" style={{ visibility: 'visible', animationDelay: '600ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/c2.jpeg" alt /></figure>
                                  
                                   
                                </div>
                                <div className="info-box">
                                    <h4 className="name">Dejian Liu</h4>
                                    <span className="designation">Beijing Normal University,China</span>
                                </div>
                            </div>
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/08.jpg" alt /></figure>
                                     
                                </div>
                                <div className="info-box">
                                    <h4 className="name">Catherine Demarey</h4>
                                    <span className="designation">Lille Catholic University, France</span>
                                </div>
                            </div>
                        </div>
                        {/* Speaker block */}


                    </div>
                    <hr /> 
                    <div className="row justify-content-center">
    <div className="sec-title light text-center">
        <h2 className="mt-3 mb-5">General Program Chairs</h2>
        <span className="divider" />
    </div>

    {/* Première ligne avec 3 speakers */}
    <div className="col-lg-4 col-md-6 col-sm-12 speaker-block-two">
        <div className="inner-box">
            <div className="image-box">
                <figure className="image"><img src="images/clients/c3.jpg" alt="Mouna denden" /></figure>
            </div>
            <div className="info-box">
                <h4 className="name">Mouna Denden</h4>
                <span className="designation">Polytechnic University of Hauts-de-France, France</span>
            </div>
        </div>
    </div>

    <div className="col-lg-4 col-md-6 col-sm-12 speaker-block-two">
        <div className="inner-box">
            <div className="image-box">
                <figure className="image"><img src="images/clients/c1.jpeg" alt="Ahmed Tlili" /></figure>
            </div>
            <div className="info-box">
                <h4 className="name">Ahmed Tlili</h4>
                <span className="designation">Beijing Normal University, China</span>
            </div>
        </div>
    </div>

    <div className="col-lg-4 col-md-6 col-sm-12 speaker-block-two">
        <div className="inner-box">
            <div className="image-box">
                <figure className="image"><img src="images/clients/c10.jpg" alt="Jerome Graindorge" /></figure>
            </div>
            <div className="info-box">
                <h4 className="name">Jerome Graindorge</h4>
                <span className="designation">Polytechnic University of Hauts-de-France, France</span>
            </div>
        </div>
    </div>

    {/* Deuxième ligne avec 2 speakers */}
    <div className="col-lg-4 col-md-6 col-sm-12 speaker-block-two">
        <div className="inner-box">
            <div className="image-box">
                <figure className="image"><img src="images/clients/c9.png" alt="Houneida Sakly" /></figure>
            </div>
            <div className="info-box">
                <h4 className="name">Houneida Sakly</h4>
                <span className="designation">CRMN-Technopole of Sousse "Novation City", Tunisia</span>
            </div>
        </div>
    </div>

    <div className="col-lg-4 col-md-6 col-sm-12 speaker-block-two">
        <div className="inner-box">
            <div className="image-box">
                <figure className="image"><img src="images/clients/9.jpg" alt="Charles Yaacoub" /></figure>
            </div>
            <div className="info-box">
                <h4 className="name">Charles Yaacoub</h4>
                <span className="designation">Lille Catholic University, France</span>
            </div>
        </div>
    </div>
</div>

                    <hr /> 
                    <div className="row d-flex justify-content-center">
                        <div className="sec-title light text-center ">

                            <h2 className='mt-3 mb-5'> Workshop Chairs</h2>
                            <span className="divider" />
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/c5.jpg" alt /></figure>
                                     
                                </div>
                                <div className="info-box">
                                    <h4 className="name">Mestiri Makram</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="300ms" style={{ visibility: 'visible', animationDelay: '300ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/c4.jpeg" alt /></figure>
                                    
                                </div>
                                <div className="info-box">
                                    <h4 className="name">TingWen Chang</h4>
                                    <span className="designation"> Beijing Normal University,China</span>
                                </div>
                            </div>
                        </div>
                        {/* Speaker block */}
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="600ms" style={{ visibility: 'visible', animationDelay: '600ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image"><img src="images/clients/8.jpg" alt /></figure>
                                    
                                </div>
                                <div className="info-box">
                                    <h4 className="name"> JALAL POSSIK</h4>
                                    <span className="designation"> Lille Catholic University, France</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr /> 

                    {/* Speaker block */}

                    <div className="row d-flex justify-content-center">
    {/* Finance Chairs */}
    <div className="sec-title light text-center col-lg-6 col-md-12">
        <h2 className="mt-3 mb-5">Finance Chair</h2>
        
        <div className="speaker-block-two col-lg-12 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
            <div className="inner-box">
                {/* <div className="image-box">
                    <figure className="image image-2">
                        <img src="images/clients/avatar.png" alt />
                    </figure>
                </div> */}
                <div className="info-box">
                    <h4 className="name">Apolline Bosquillon</h4>
                    <span className="designation">Polytechnic University<br />of Hauts-de-France, France</span>
                </div>
            </div>
        </div>
    </div>

    {/* Website Chairs */}
    <div className="sec-title light text-center col-lg-6 col-md-12">
        <h2 className="mt-3 mb-5">Website Chair</h2>
    
        <div className="speaker-block-two col-lg-12 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
            <div className="inner-box">
                {/* <div className="image-box">
                    <figure className="image image-2">
                        <img src="images/clients/file.jpg" alt />
                    </figure>
                </div> */}
                <div className="info-box">
                    <h4 className="name">Sarra Kabaou</h4>
                    <span className="designation">Polytechnic University<br />of Hauts-de-France, France</span>
                </div>
            </div>
        </div>
    </div>
</div>
<hr /> 
                    {/* Speaker block */}

                    <div className="row">
                        <div className="sec-title light text-center ">

                            <h2 className='mt-3 mb-5'>Organizing Committees</h2>
                            <span className="divider" />
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                          
                                <div className="info-box">
                                    <h4 className="name"> Yamina Ghobni</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                
                                <div className="info-box">
                                    <h4 className="name">  Rihab Balti</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                 
                                <div className="info-box">
                                    <h4 className="name">  Maher Krimi </h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                
                                <div className="info-box">
                                    <h4 className="name">  khalil Jedidi</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                
                                <div className="info-box">
                                    <h4 className="name"> Thi-Phuong Nguyen</h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                       
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                             
                                <div className="info-box">
                                    <h4 className="name">  Eyaa Naimi  </h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>
                        <div className="speaker-block-two col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                           
                                <div className="info-box"> 
                                    <h4 className="name"> Waha Noomene   </h4>
                                    <span className="designation">Polytechnic University  <br />of Hauts-de-France, France</span>
                                </div>
                            </div>
                        </div>


                     
                    </div>
                </div>
            </section >



        </>
    )
}

export default Coomitee