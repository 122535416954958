import React from 'react'

function SpeakerDetails2() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/spek.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Speakers</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">conference</a></li>
                            <li>Speakers Details</li>
                        </ul>
                    </div>
                </div> 
            </section>

            <section className="speaker-detail-section">
    <div className="auto-container">
        <div className="row no-gutters">
            
            {/* Colonne avec la photo */}
            <div className="image-column col-lg-6 col-md-6 col-sm-12" style={{ paddingRight: '10px', textAlign: 'center' }}>
                <div className="speaker-block wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                    <div className="inner-box">
                        <div className="image-box">
                            <figure className="image"><a href="#"><img src="images/clients/sp3.png" alt="Speaker" /></a></figure>
                            <span className="plus-icon fa fa-plus" />
                            <div className="social-links">
                                <a href="#"><i className="fab fa-linkedin" /></a>
                            </div>
                        </div>
                    </div>
                    {/* Détails du speaker, centrés sous l'image */}
                    <div className="speaker-details" style={{ marginTop: '10px' }}>
                        <h4 className="name"><a href="#">Rrahul Sethi</a></h4>
                        <span className="designation"> Founder of Metaverse911 </span>
                        <ul className="speaker-info-list" style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                            <li><strong>Speciality:</strong> Metaverse</li>
                            <li><strong>Company:</strong> Metaverse911</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            {/* Colonne avec les informations personnelles */}
            <div className="info-column col-lg-6 col-md-6 col-sm-12" style={{ paddingLeft: '10px' }}>
                <h3>Personal Information</h3>
                <p style={{ textAlign: 'justify' }}>Rrahul Sethi, Founder of Metaverse911 and a LinkedIn Top Voice, with a community of over 30,000+ professionals, driving immersive tech adoption worldwide. He has a rich entrepreneurial background across 20+ countries and advises BIG5 firms and corporates on Metaverse strategies for large-scale implementations.</p>

                <p style={{ textAlign: 'justify' }}>Over 3500+ professionals from leading firms like Deloitte, Allianz, Continental, Unilever, Starbucks, Times Now, and others have attended Rrahul's Masterclass on Web3 & Metaverse. Additionally, more than 100,000+ individuals have tuned into his LinkedIn LIVE sessions in the past 14 months.</p>

                <p style={{ textAlign: 'justify' }}>He is a sought-after keynote speaker and panelist and has delivered impactful talks at prestigious summits including NASSCOM, BRICS Alliance, and VRAR Global Summit EU, shaping the discourse on Web3 and the Metaverse.</p>
            </div>

        </div>
    </div>
</section>




















        </>
    )
}

export default SpeakerDetails2